<template>
  <div class="container">
    <div class="main-left">
      <van-radio-group v-model="paymentMethodType">
        <van-cell-group
          title="Selection payment method"
          v-if="presentmentMode === '02'"
        >
          <van-cell title="" clickable @click="paymentMethodType = ''">
            <template #title>
              <div class="flex">
                <img :src="ailpayLogo" />
              </div>
            </template>
            <template #right-icon>
              <div class="flex">
                <div class="logos">
                  <img
                    v-for="item in brands"
                    :key="item.value"
                    :src="item.logo"
                  />
                </div>
                <van-radio name="" />
              </div>
            </template>
          </van-cell>
        </van-cell-group>
        <van-cell-group title="Selection payment method" v-else>
          <van-cell
            v-for="item in brands"
            :key="item.value"
            title=""
            clickable
            @click="paymentMethodType = item.value"
          >
            <template #title>
              <div class="flex">
                <img :src="item.logo" alt="" />
                <span>{{ item.walletBrandName }}</span>
              </div>
            </template>
            <template #right-icon>
              <img :src="ailpayLogo" class="ailpay-logo" />
              <van-radio :name="item.value" />
            </template>
          </van-cell>
        </van-cell-group>
      </van-radio-group>
    </div>
    <div class="main-right">
      <van-card
        num="1"
        origin-price="120"
        price="1"
        desc="Goods descript"
        title="Goods Title"
        thumb="https://img01.yzcdn.cn/vant/ipad.jpeg"
      />
      <van-cell-group style="margin: 20px 0">
        <van-cell title="Goods Price" value="JPY 1" />
        <van-cell title="Shipping Price" value="JPY 0" />
        <van-cell title="Tax" value="JPY 0" />
        <van-cell title="Total" :value="`JPY ${orderPrice}`" />
      </van-cell-group>
      <van-button type="primary" block :loading="loading" @click="handlePay">
        Continue to pay
      </van-button>
    </div>

    <div v-if="visible" class="modal">
      <div class="modal-body">
        <a class="close" @click="handleClose"><van-icon name="cross" /></a>
        <div class="qr-left">
          <p class="amount">
            JPY <span class="blue">{{ orderPrice }}</span>
          </p>

          <vue-qr :logoSrc="logo" :text="codeUrl" :size="240" />
          <div class="time">
            QR code will expire in
            <van-icon name="underway-o" />
            <van-count-down :time="time" format="mm min ss s" />
          </div>
        </div>

        <div class="qr-right">
          <h4>You can scan and pay using:</h4>
          <div class="flex">
            <div v-if="presentmentMode === '02'" class="logos">
              <img v-for="item in brands" :key="item.value" :src="item.logo" />
            </div>
          </div>
          <small
            >(Download and use any of these supported wallet apps to pay)</small
          >

          <p>How to use:</p>
          <ul>
            <li>1. Open your wallet app on your device</li>
            <li>2. Tap on the “Scan” feature</li>
            <li>3. Scan QR code on the left to complete payment</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueQr from 'vue-qr'
import { openCreate, openGet, openClose } from '@/apis'
import logo from '@/assets/logo.jpg'

export default {
  name: 'Index',
  components: { VueQr },
  data() {
    return {
      logo,
      visible: false,
      loading: false,
      paymentMethodType: '',
      presentmentMode: '',
      ailpayLogo: '',
      orderPrice: 1,
      brands: [],
      time: 10 * 60 * 1000,
      codeUrl: '',
      clientOrderNo: '',
      gwOrderNo: '',
    }
  },
  computed: {
    brand() {
      return this.brands.find((item) => {
        return this.paymentMethodType === item.value
      })
    },
  },
  created() {},
  methods: {
    handlePay() {
      const { orderPrice } = this
      const clientOrderNo = new Date().getTime()
      this.loading = true
      openCreate({
        brandType: '02',
        clientOrderNo,
        isSync: 1,
        orderPrice,
        orderSubject: 'Wechat Purchased',
        payType: '02',
        returnUrl: 'https://demo.ctshop.colossaltech.cn',
      })
        .then((res) => {
          localStorage.setItem('clientOrderNo', res.clientOrderNo)
          this.clientOrderNo = res.clientOrderNo
          this.gwOrderNo = res.gwOrderNo
          this.visible = true
          this.codeUrl = res.codeUrl
          this.checkResult()
          this.loading = false
        })
        .catch((_) => {
          this.loading = false
        })
    },
    checkResult() {
      const clientOrderNo = this.clientOrderNo
      openGet({ clientOrderNo }).then((res) => {
        if (res.orderStatus === 1) {
          this.$router.push({
            path: '/result',
            query: {
              clientOrderNo,
            },
          })
        } else {
          setTimeout(() => {
            this.checkResult()
          }, 5000)
        }
      })
    },
    handleClose() {
      const clientOrderNo = this.clientOrderNo
      const gwOrderNo = this.gwOrderNo
      this.visible = false
      openClose({ clientOrderNo, gwOrderNo })
    },
  },
}
</script>

<style scoped lang="less">
.container {
  width: 1080px;
  margin: 0 auto;
}
.van-cell--clickable {
  align-items: center;
}
.main-left {
  margin-top: 60px;
  padding: 40px;
  float: left;
  width: 740px;
  height: 480px;
  min-height: 447px;
  background: #fff;
  box-sizing: border-box;
}
.main-right {
  margin-top: 60px;
  padding: 20px;
  float: right;
  width: 320px;
  height: 480px;
  background: #fff;
  box-sizing: border-box;
}
.flex {
  display: flex;
  align-items: center;
  font-weight: 600;
}
.van-cell-group img,
.logos img {
  margin-right: 10px;
  height: 40px;
  &.ailpay-logo {
    height: 30px;
  }
}
.van-button {
  width: 80%;
  margin: 40px auto;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
}
.modal-body {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 800px;
  transform: translate(-50%, -50%);
  background: #fff;
  display: flex;
  justify-content: space-between;
  padding: 60px;
  .close {
    position: absolute;
    top: 20px;
    right: 30px;
    cursor: pointer;
  }

  .qr-left {
    text-align: center;
    .amount {
      text-align: center;
      margin: 0;
      font-weight: 600;
      font-size: 24px;
    }
    .blue {
      color: #1677ff;
    }
  }
  .qr-right {
    small {
      color: #999;
    }
    h4 {
      font-size: 20px;
    }
    li {
      color: #666;
      font-size: 14px;
      line-height: 2;
    }
  }
  .time {
    display: flex;
    align-items: center;
    justify-content: center;
    .van-icon {
      margin-left: 5px;
      color: #f00;
    }
    .van-count-down {
      margin-left: 5px;
      color: #f00;
    }
  }
}
</style>
